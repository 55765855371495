<template>
<b-container fluid>
  <bo-page-title>
    <template #additionalCta>
      <b-button
        variant="success"
        class="m-l-15 btn-rounded"
        @click="openModalAdd"
      >
        <i class="fa fa-plus-circle mr-2" />
        Add {{ objName }}
      </b-button>
    </template>
  </bo-page-title>

  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col lg="5">
          <h5 class="card-title">{{ pageTitle }} </h5>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select label="text" placeholder="Select Status" v-model="filter.status" :reduce="value => value.value" @input="doFilter()" :options="statusOptions" />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row class="g-2 row-cols-md-5">
        <b-col v-for="(value, index) in data.data" :key="index">
          <article class="card_list">
            <div class="wrap_thumb_list h-100 px-2">
              <img :src="uploader(value.mc_logo)" :alt="value.mc_name" :title="value.mc_name" />
              <div class="bullet-cta">
                <b-button
                  variant="secondary"
                  size="sm"
                  pill
                  v-if="moduleRole('edit')"
                  @click="openModalEdit(value)"
                  class="btn-icon"
                  type="button"
                  v-b-tooltip.hover="'Update Content'"
                >
                  <i class="fas fa-pencil-alt" />
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  pill
                  v-if="moduleRole('delete')"
                  @click="deleteItem(value)"
                  class="btn-icon"
                  v-b-tooltip.hover="'Delete Content'"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </div>
            </div>
            <div class="wrap_post_content">
              <h5 class="mb-1">{{ value.mc_name }}</h5>
              <template v-if="value.mc_is_active == 'Y'">
                <span class="label label-success">Active</span>
              </template>
              <template v-else>
                <span class="label label-danger">Inactive</span>
              </template>
            </div>
          </article>
        </b-col>
      </b-row>      
    </b-card-body>
  </b-card>

  <b-modal id="modal_form" :title="(dataModal.mc_id ? 'Update' : 'Add') + ' Client'" no-close-on-backdrop>
    <validation-observer ref="VForm" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submit)">
        <b-form-group>
          <template #label>Client Name <span class="text-danger">*</span></template>
          <b-form-input v-model="dataModal.mc_name" placeholder="e.g. Investree"></b-form-input>
          <VValidate name="Client Name" v-model="dataModal.mc_name" :rules="mrValidation.mc_name" />
        </b-form-group>
        <b-form-group>
          <template #label>Client Link</template>
          <b-form-input v-model="dataModal.mc_link" placeholder="e.g. https://investre.id"></b-form-input>
          <VValidate name="Client Link" v-model="dataModal.mc_link" :rules="mrValidation.mc_link" />
        </b-form-group>
        <b-form-group>
          <div class="file-uploader">
            <label>Client Logo (Default) <span class="text-danger mr5">*</span></label>
            <Uploader v-model="dataModal.mc_logo" type="client"/>
            <VValidate 
              name="Logo Default" 
              v-model="dataModal.mc_logo" 
              :rules="mrValidation.mc_logo" 
            />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="file-uploader">
            <label>Client Logo (White) <span class="text-danger mr5">*</span></label>
            <Uploader v-model="dataModal.mc_logo_white" type="client"/>
            <VValidate 
              name="Logo White" 
              v-model="dataModal.mc_logo_white" 
              :rules="mrValidation.mc_logo_white" 
            />
          </div>
        </b-form-group>
        <b-form-group>
          <label>Status<span class="text-danger mr5">*</span></label>
          <b-form-radio-group
            :options="Config.mr.StatusOptions"
            v-model="dataModal.mc_is_active"
          />
          <VValidate 
            name="Status" 
            v-model="dataModal.mc_is_active" 
            :rules="mrValidation.mc_is_active" 
          />
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button type="button" variant="secondary" @click="closeModal">Close</b-button>
      <b-button type="button" variant="primary" @click="submit">Submit</b-button>
    </template>
  </b-modal>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'

const _ = global._

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'mc_id',
      statusKey:'mc_is_active',
      dataModal: {}
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    deleteItem(v){
      this.swalDelete().then(result => {
        if(result.value){
          this.swalLoading()

          Gen.apiRest("/do/" + this.modulePage, {
            data: {
              type: 'delete',
              id: v.mc_id
            }
          }).then(res => {
            this.$swal({
              icon: 'success',
              text: res.data.message
            }).then(() => {
              this.apiGet()
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || "Terjadi Suatu Kesalahan"
            })
          })
        }
      })
    },
    openModalEdit(value){
      this.$set(this, 'dataModal', _.clone(value))
      this.$bvModal.show('modal_form')
    },
    openModalAdd(){
      this.$set(this, 'dataModal', {
        mc_is_active: 'Y'
      })
      this.$bvModal.show('modal_form')
    },
    closeModal(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('modal_form')
    },
    submit(){
      this.$refs.VForm.validate().then(success => {
        if(!success) return

        this.swalLoading()

        Gen.apiRest("/do/" + this.modulePage, {
          data: {
            type: this.dataModal.mc_id ? 'update' : 'add',
            ...this.dataModal
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            text: res.data.message
          }).then(() => {
            this.$bvModal.hide('modal_form')
            this.apiGet()
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Terjadi Suatu Kesalahan!'
          })
        })
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>